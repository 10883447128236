import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

// MUI Icons
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PDFIcon from "@mui/icons-material/PictureAsPdfOutlined";

// Import your logo (adjust the path as needed)
import logo from "../assets/logo.webp";
import { productCategories } from "../constants/products";

function Footer() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || "en";
  const otherLang = currentLang === "en" ? "hr" : "en";

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box
      component="footer"
      sx={{ bgcolor: "#f5f5f5", color: "#333", py: 4, mt: "auto" }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {/* Left column: Logo + Contact Info */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ mb: 2 }}>
              <Box
                component="img"
                src={logo}
                alt="Infinitiv Logo"
                sx={{ height: 40, mb: 1 }}
              />
            </Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {/* Optional short description */}
              {t("shortCompanyDescription") || "Your healthy food wholesaler."}
            </Typography>
            <Typography variant="body2">
              <strong>{t("phone")}:</strong>{" "}
              <a
                href="tel:+38512301143"
                style={{ color: "#333" }}
                target="_blank"
              >
                +385 1 2301 143
              </a>
            </Typography>
            <Typography variant="body2">
              <strong>{t("email")}:</strong>{" "}
              <a
                href="mailto:infinitiv@infinitiv.hr"
                style={{ color: "#333" }}
                target="_blank"
              >
                infinitiv@infinitiv.hr
              </a>
            </Typography>
          </Grid>

          {/* Middle column: Quick Links (Home, About, Contact) */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("quickLinks")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                {t("home")}
              </Link>
              <Link
                to="/o-nama"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("about")}
              </Link>
              <Link
                to="/kontakt"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {t("contact")}
              </Link>
            </Box>

            {/* Button to open PDF file */}
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                href="/documents/INFINITIV_UNACC_HACCP_hr.pdf"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#333", borderColor: "#333" }}
              >
                <PDFIcon sx={{ mr: 1 }} />
                {"HACCP PDF"}
              </Button>
            </Box>
          </Grid>

          {/* Middle column: Product Categories */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("products")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              {productCategories.map((cat) => (
                <Link
                  key={cat.category[currentLang]}
                  to={
                    currentLang === "en"
                      ? "category/" + cat.route.en
                      : "kategorija/" + cat.route.hr
                  }
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {cat.category[currentLang]}
                </Link>
              ))}
            </Box>
          </Grid>

          {/* Right column: Language + Social Icons */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("language")}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => changeLanguage(otherLang)}
              startIcon={<LanguageIcon />}
              sx={{ mb: 2 }}
            >
              {otherLang.toUpperCase()}
            </Button>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("followUs")}
            </Typography>
            <Box>
              {/* Facebook */}
              <IconButton
                component="a"
                href="https://www.facebook.com/infinitiv.doo"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#333" }}
              >
                <FacebookIcon />
              </IconButton>
              {/* Instagram */}
              <IconButton
                component="a"
                href="https://instagram.com/infinitivdoo"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#333" }}
              >
                <InstagramIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright Line */}
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Infinitiv {t("madeBy")}{" "}
            <a
              href="https://bluebitline-web.vercel.app"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#333" }}
            >
              BlueBitLine
            </a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
