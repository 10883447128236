import React, { useState, useEffect } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

const ParallaxImage = ({
  image,
  title,
  supertitle = "",
  height = "50vh",
  button,
  textShadow,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [offsetY, setOffsetY] = useState(0);

  // Update offsetY state on scroll
  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };

  // Attach the scroll listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Parallax effect: image moves at 50% (0.5) the scroll rate
  const parallaxStyle = {
    transform: `translateY(${offsetY * 0.5}px)`,
    backgroundImage: `url(${image})`,
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height,
        overflow: "hidden",
      }}
    >
      {/* Parallax background layer */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          willChange: "transform",
          ...parallaxStyle,
        }}
      />

      {/* Foreground content layer */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "80vw" : "50vw",
            mb: 4,
          }}
        >
          {supertitle.split("\n").map((line) => (
            <Typography
              key={line}
              variant="h3"
              sx={[
                { color: "#319b31", mb: 2, lineHeight: 1, textAlign: "center" },
                textShadow && { textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" },
              ]}
            >
              {line}
            </Typography>
          ))}
          {title.split("\n").map((line) => (
            <Typography
              key={line}
              variant="h3"
              sx={[
                { color: "#fff", mb: 2, lineHeight: 1 },
                textShadow && { textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" },
              ]}
            >
              {line}
            </Typography>
          ))}
        </Box>

        {!!button && (
          <Button
            variant="outlined"
            component={Link}
            to={button.link}
            sx={{
              color: "#fff",
              borderColor: "#fff",
              borderWidth: "2px",
              borderRadius: "30px",
              backgroundColor: "transparent",
              textTransform: "uppercase",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderColor: "#fff",
              },
              fontSize: "1rem",
            }}
          >
            {button.text}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ParallaxImage;
