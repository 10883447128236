// src/pages/ContactPage.js
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Map from "../components/Map";
import { useTranslation } from "react-i18next";
import ContactUsForm from "../components/ContactUsForm";

const contacts = [
  {
    title: "management",
    name: "Infinitiv d.o.o.",
    emails: ["infinitiv@infinitiv.hr"],
    telephones: ["+385 (01) 2301 143"],
    mobilePhones: ["+385 98 417 352", "+385 98 958 2786"],
    address: "Maksimirska 118, Zagreb",
    location: { lat: 45.81862376494912, lng: 16.012163564936504 },
  },
  {
    title: "warehouse",
    name: "Infinitiv d.o.o.",
    telephones: ["+385 (01) 2415 448"],
    mobilePhones: [],
    address:
      "Slavonska avenija 52, Robni terminali Žitnjak, Hala 11 i Hala 7, Zagreb",
    location: { lat: 45.797870781618954, lng: 16.05873207112807 },
  },
  {
    title: "office",
    name: "Infinitiv d.o.o.",
    emails: [
      "infinitiv@infinitiv.hr",
      "prodaja@infinitiv.hr",
      "renata@infinitiv.hr",
    ],
    telephones: ["+385 (01) 2301 143"],
    mobilePhones: [],
    address: "Maksimirska 96, Zagreb",
    location: { lat: 45.817722345651894, lng: 16.00906797858804 },
  },
];

function ContactPage() {
  const { t } = useTranslation();

  return (
    <div>
      <ContactUsForm />

      <Box
        sx={{ padding: { xs: "16px", md: "40px" }, backgroundColor: "#fff" }}
      >
        <Grid container spacing={4}>
          {contacts.map((contact, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between", // Aligns content vertically
              }}
            >
              <Box
                sx={{
                  padding: "12px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" sx={{ marginBottom: "8px" }}>
                  {t(`contact.${contact.title}`)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "4px", color: "#319b31" }}
                >
                  {contact.name}
                </Typography>
                {contact.emails?.map((email, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    <a
                      href={`mailto:${email}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      {email}
                    </a>
                  </Typography>
                ))}
                {[...contact.telephones, ...contact.mobilePhones]?.map(
                  (telephone, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      <a
                        href={`tel:${telephone}`}
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        {telephone}
                      </a>
                    </Typography>
                  )
                )}
                <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                  {contact.address}
                </Typography>
              </Box>

              {/* Map Container with equal height alignment */}
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Map
                  location={contact.location}
                  zoom={15}
                  mapWidth="100%"
                  mapHeight="100%"
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default ContactPage;
