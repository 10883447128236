// src/pages/AboutPage.js
import React from "react";
import ParallaxImage from "../components/ParallaxImage";
import background_5 from "../assets/backgrounds/background_5.jpg";
import background_6 from "../assets/backgrounds/background_6.jpg";
import { useTranslation } from "react-i18next";
import ImageWithTexts from "../components/ImageWithTexts";

function AboutPage() {
  const { t } = useTranslation();

  return (
    <div>
      <ParallaxImage
        image={background_6}
        title={t("about").toUpperCase()}
        height="60vh"
        textShadow
      />

      <ImageWithTexts
        image={background_5}
        texts={[
          {
            title: t("aboutUs.title"),
            description: t("aboutUs.description1"),
          },
          {
            title: t("aboutUs.title1"),
            description: t("aboutUs.description2"),
          },
          {
            title: t("aboutUs.title2"),
            description: t("aboutUs.description3"),
          },
        ]}
        textPosition="right"
      />
    </div>
  );
}

export default AboutPage;
