import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";

const ImageTitle = ({ items, title, description }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        py: 6,
        px: isMobile ? 6 : 24,
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Carousel Title */}
      {title && (
        <Typography
          variant="h3"
          sx={{
            mt: isMobile ? 2 : 4,
            mb: description ? 1 : 4,
            textAlign: "center", // Centers the title
          }}
        >
          {title}
        </Typography>
      )}
      {/* Carousel Description */}
      {description && (
        <Typography
          variant="body1"
          sx={{ mb: 4, color: "text.secondary", textAlign: "center" }}
        >
          {description}
        </Typography>
      )}

      {/* Image-Text Cards */}
      <Grid container spacing={isMobile ? 4 : 0} justifyContent="center">
        {items.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            key={index}
            sx={{ py: 0, px: 0, my: 0, mx: 0 }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: isMobile
                  ? "column"
                  : index % 2 === 0
                  ? "row"
                  : "row-reverse",
                backgroundColor: "#fff",
                boxShadow: "none",
                overflow: "hidden",
                py: 0,
                px: 0,
                my: 0,
                mx: 0,
              }}
            >
              {/* Image */}
              <Box
                sx={{
                  width: isMobile ? "100%" : "50%",
                  height: "350px", // Ensure height is consistent
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />

              {/* Content (Title + Description) */}
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  textAlign: "left",
                }}
              >
                <Typography variant="h4" sx={{}}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ImageTitle.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ImageTitle;
