import React, { useState } from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContactUsForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    telephone: "",
    message: "",
  });

  const [status, setStatus] = useState(""); // Used to show success or error message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("telephone", formData.telephone);
    formDataToSend.append("message", formData.message);

    try {
      const response = await fetch("https://formspree.io/f/xvgzrenp", {
        method: "POST",
        body: formDataToSend,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setStatus("success");
        setFormData({
          fullName: "",
          email: "",
          telephone: "",
          message: "",
        });
      } else {
        setStatus("error");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(backgrounds/background_6.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: { xs: "40px", md: "80px" },
      }}
    >
      <Box
        sx={{
          padding: { xs: "16px", md: "40px" },
          maxWidth: "600px",
          margin: "0 auto",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Typography variant="h4" align="center" sx={{ marginBottom: "16px" }}>
          {t("contactUsForm.title")}
        </Typography>

        {status === "success" && (
          <Alert severity="success">{t("contactUsForm.successMessage")}</Alert>
        )}
        {status === "error" && (
          <Alert severity="error">{t("contactUsForm.errorMessage")}</Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            label={t("contactUsForm.fullName")}
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            fullWidth
            required
            sx={{ marginBottom: "16px", marginTop: "16px" }}
          />

          <TextField
            label={t("contactUsForm.email")}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
            sx={{ marginBottom: "16px" }}
          />

          <TextField
            label={t("contactUsForm.telephone")}
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
            fullWidth
            sx={{ marginBottom: "16px" }}
          />

          <TextField
            label={t("contactUsForm.message")}
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            required
            multiline
            rows={4}
            sx={{ marginBottom: "24px" }}
          />

          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ backgroundColor: "#319b31", color: "#fff" }}
          >
            {t("contactUsForm.submit")}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ContactUsForm;
