import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

const ImageWithTexts = ({ texts, image }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundImage: `url(${image}), linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
        backgroundSize: "cover",
        backgroundPosition: isMobile ? "center" : "right",
        position: "relative",
        width: "100%",
        py: { xs: "10%", md: "5%" },
        backgroundBlendMode: "overlay",
      }}
    >
      {/* Text Content Layer */}
      {texts.map(({ title, description }, index) => (
        <Box
          sx={{
            width: { xs: "90%", md: "40%" },
            paddingLeft: { xs: "5%", md: "50%" },
            paddingRight: { xs: "5%", md: "10%" },
            paddingY: { xs: "2%", md: "1%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: { xs: "center", md: "center" },
            zIndex: 1,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 1,
              lineHeight: 1.2,
              color: "#fff",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              lineHeight: 1.2,
              color: "#fff",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            {description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ImageWithTexts;
