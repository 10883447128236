import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Import the CategoryCard component
import CategoryCard from "./CategoryCard";

function CategoryGrid({ categoryList, title, description, language }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ py: 4 }}>
      <Container maxWidth="lg">
        {/* Carousel Title */}
        {title && (
          <Typography
            variant="h3"
            sx={{
              mt: isMobile ? 2 : 4,
              mb: description ? 1 : 4,
              textAlign: "center", // Centers the title
            }}
          >
            {title}
          </Typography>
        )}
        {/* Carousel Description */}
        {description && (
          <Typography
            variant="body1"
            sx={{ mb: 4, color: "text.secondary", textAlign: "center" }}
          >
            {description}
          </Typography>
        )}

        {/* Grid Content */}
        <Grid container spacing={2}>
          {categoryList.map((category, index) => (
            <Grid
              key={index}
              item
              xs={12} // Full width on mobile (1 column)
              sm={6} // 50% width on small screens (2 columns)
            >
              <CategoryCard category={category} language={language} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

CategoryGrid.propTypes = {
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default CategoryGrid;
