// src/pages/HomePage.js
import React from "react";
import background_1 from "../assets/backgrounds/background_1.jpg";

import { useTranslation } from "react-i18next";
import point2Image from "../assets/utility/delivery.webp";
import point3Image from "../assets/utility/nuts-bowl.jpg";
import point1Image from "../assets/utility/nuts.webp";
import CategoryGrid from "../components/CategoryGrid";
import ImageTitle from "../components/ImageTitle";
import ParallaxImage from "../components/ParallaxImage";
import { productCategories } from "../constants/products";

function HomePage() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || "en";

  return (
    <div>
      <ParallaxImage
        image={background_1}
        title={t("home.title")}
        button={{
          text: t("contactUs"),
          link: currentLang === "en" ? "/contact" : "/kontakt",
        }}
        textShadow
        height="80vh"
      />

      <CategoryGrid
        title={t("home.ourProducts")}
        description={t("home.ourProductsDescription")}
        categoryList={productCategories}
        language={currentLang}
      />

      <ImageTitle
        title={t("home.whyChooseUs")}
        items={[
          {
            image: point1Image,
            title: t("home.point1"),
          },
          {
            image: point2Image,
            title: t("home.point2"),
          },
          {
            image: point3Image,
            title: t("home.point3"),
          },
        ]}
      />
    </div>
  );
}

export default HomePage;
