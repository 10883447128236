// src/pages/Category.js
import React from "react";
import { useParams } from "react-router-dom";
import ParallaxImage from "../components/ParallaxImage";
import { productCategories } from "../constants/products";
import { useTranslation } from "react-i18next";
import ProductGrid from "../components/ProductGrid";

function Category() {
  const { categoryName } = useParams();
  const { i18n, t } = useTranslation();

  const currentLang = i18n.language || "en";

  const category = productCategories.find(
    (category) =>
      category.route.en === categoryName || category.route.hr === categoryName
  );

  const products = category.products;

  return (
    <div>
      <ParallaxImage
        image={require(`../assets/categoryImages/${category.image}`)}
        title={category.category[currentLang].toUpperCase()}
        height="70vh"
        textShadow
      />

      <ProductGrid
        products={products}
        title={t("category.products")}
        description={t("category.productsDescription")}
      />
    </div>
  );
}

export default Category;
