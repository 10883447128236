import PropTypes from "prop-types";
import React from "react";

// MUI Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

function CategoryCard({ category, language }) {
  const { category: categoryName, image, imageAlt, route } = category;

  return (
    <Link
      to={language === "en" ? "category/" + route.en : "kategorija/" + route.hr}
    >
      <Card
        sx={{
          mx: "auto",
          boxShadow: 6,
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
          },
          position: "relative",
        }}
      >
        {/* Hover Zoom Effect on Image */}
        {image && (
          <CardMedia
            component="img"
            height="250"
            image={"categoryImages/" + image}
            alt={imageAlt}
            sx={{
              objectFit: "cover",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          />
        )}

        {/* Category Title */}
        <CardContent
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: 2,
          }}
        >
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            textTransform={"uppercase"}
          >
            {categoryName[language]}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

CategoryCard.propTypes = {
  category: PropTypes.shape({
    category: PropTypes.string.isRequired,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
  }).isRequired,
};

export default CategoryCard;
