export const productCategories = [
  {
    category: {
      en: "Nuts",
      hr: "Orašasti plodovi",
    },
    route: {
      en: "nuts",
      hr: "orasasti-plodovi",
    },
    image: "nuts.jpg",
    imageAlt: "Image by atlascompany on Freepik",
    products: [
      { en: "ALMONDS", hr: "BADEM" },
      { en: "ALMONDS BLANCHED WHOLE", hr: "BADEM BLANŠIRANI CIJELI" },
      { en: "ALMONDS BLANCHED SLICED", hr: "BADEM BLANŠIRANI LISTIĆI" },
      { en: "PEANUT KERNELS", hr: "KIKIRIKI JEZGRA" },
      { en: "PEANUTS ROASTED SALTED", hr: "KIKIRIKI PRŽENI SLANI" },
      { en: "PEANUTS ROASTED UNSALTED", hr: "KIKIRIKI PRŽENI NESLANI" },
      { en: "PEANUTS ROASTED IN SHELL", hr: "KIKIRIKI PRŽENI U LJUSCI" },
      { en: "HAZELNUTS RAW", hr: "LJEŠNJAK SIROVI" },
      { en: "HAZELNUTS ROASTED", hr: "LJEŠNJAK PRŽENI" },
      { en: "HAZELNUTS ROASTED CHOPPED", hr: "LJEŠNJAK PRŽENI SJECKANI" },
      { en: "MIXED TROPICAL FRUIT", hr: "MACEDONIA TROPIC" },
      { en: "STUDENT MIX", hr: "MACEDONIA STUDENT" },
      { en: "WALNUT HALVES 30%", hr: "ORAH POLOVINKE 30%" },
      { en: "PECAN WALNUT HALVES", hr: "ORAH PEKAN POLOVICE" },
      { en: "BRAZIL NUTS", hr: "ORAH BRAZILSKI" },
      { en: "CASHEW NUTS RAW", hr: "ORAH INDIJSKI SIROVI" },
      { en: "CASHEW NUTS ROASTED", hr: "ORAH INDIJSKI PRŽENI" },
      { en: "WALNUT HALVES LIGHT", hr: "ORAH POLOVINKE LIGHT" },
      { en: "WALNUT HALVES EXTRA LIGHT", hr: "ORAH POLOVINKE EXTRA LIGHT" },
      { en: "PISTACHIOS SHELLED RAW", hr: "PISTACIO OLJUŠTENI SIROVI" },
      { en: "PISTACHIOS ROASTED SHELL", hr: "PISTACIO PRŽENI LJUSKA" },
      { en: "PISTACHIOS ROASTED CHOPPED", hr: "PISTACIO PRŽENI SJECKANI" },
      { en: "COCONUT FLAKES", hr: "KOKOS RIBANI" },
    ],
  },
  {
    category: {
      en: "Seeds and Grains",
      hr: "Sjemenke i žitarice",
    },
    route: {
      en: "seeds-and-grains",
      hr: "sjemenke-i-zitarice",
    },
    image: "seeds.jpg",
    imageAlt: "Image by azerbaijan_stockers on Freepik",
    products: [
      { en: "PUMPKIN SEEDS GWS AA", hr: "BUČINA GOLICA GWS AA" },
      { en: "PUMPKIN SEED CORES", hr: "BUČINA KOŠTICA" },
      { en: "CHIA", hr: "CHIA" },
      { en: "BEANS", hr: "GRAH" },
      { en: "BUCKWHEAT GROATS", hr: "HAJDINA KAŠA" },
      { en: "BARLEY GROATS", hr: "JEČMENA KAŠA" },
      { en: "POPCORN", hr: "KOKICE" },
      { en: "COUSCOUS", hr: "KUS KUS" },
      { en: "FLAXSEED", hr: "LAN" },
      { en: "YELLOW FLAXSEED", hr: "LAN ŽUTI" },
      { en: "RED LENTILS", hr: "LEĆA CRVENA" },
      { en: "GREEN LENTILS", hr: "LEĆA ZELENA" },
      { en: "MIXED LEGUMES", hr: "MAHUNARKE MJEŠAVINA" },
      { en: "POPPY SEEDS", hr: "MAK ZRNO" },
      { en: "GROUND POPPY SEEDS", hr: "MAK MLJEVENI" },
      { en: "PINE NUTS", hr: "PINJOLI" },
      { en: "MILLET", hr: "PROSO" },
      { en: "WHITE/RED QUINOA", hr: "QUINOA BIJELA/CRVENA" },
      { en: "LONG GRAIN WHITE RICE", hr: "RIŽA BIJELA DUGO ZRNO" },
      { en: "BASMATI RICE", hr: "RIŽA BASMATI" },
      { en: "WHITE SESAME", hr: "SEZAM BIJELI" },
      { en: "BLACK SESAME", hr: "SEZAM CRNI" },
      { en: "INTEGRAL SESAME", hr: "SEZAM INTEGRALNI" },
      { en: "CHICKPEAS", hr: "SLANUTAK" },
      { en: "SUNFLOWER SEEDS", hr: "SUNCOKRET" },
      { en: "SMALL OAT FLAKES", hr: "ZOBENE PAHULJICE SITNE" },
      { en: "LARGE OAT FLAKES", hr: "ZOBENE PAHULJICE KRUPNE" },
    ],
  },
  {
    category: {
      en: "Dried Fruit",
      hr: "Suho voće",
    },
    route: {
      en: "dried-fruit",
      hr: "suho-voce",
    },
    image: "dried-fruit.jpg",
    imageAlt: "Image by stockking freepik",
    products: [
      { en: "CANDIED PINEAPPLE", hr: "ANANAS DUKAT" },
      { en: "PINEAPPLE CUBES", hr: "ANANAS KOCKA" },
      { en: "BANANA CHIPS", hr: "BANANA ČIPS" },
      { en: "CRANBERRIES", hr: "BRUSNICA" },
      { en: "DATES", hr: "DATULJA" },
      { en: "GOJI BERRIES", hr: "GOJI" },
      { en: "RAISINS", hr: "GROŽĐICE" },
      { en: "GOLDEN RAISINS", hr: "GROŽĐICE ZLATNE" },
      { en: "CANDIED GINGER PIECES", hr: "INGVIR UŠEĆERENI KOMADI" },
      { en: "CANDIED GINGER SLICES", hr: "INGVIR UŠEĆERENI LISTIĆI" },
      { en: "SMOOTH GINGER PIECES", hr: "INGVIR GLATKI KOMADI" },
      { en: "SMOOTH GINGER SLICES", hr: "INGVIR GLATKI LISTIĆI" },
      { en: "APPLE CHIPS", hr: "JABUKA ČIPS" },
      { en: "MANGO CUBES", hr: "MANGO KOCKA" },
      { en: "MANGO PIECES", hr: "MANGO KOMADI" },
      { en: "LARGE APRICOTS", hr: "MARELICA KRUPNA" },
      { en: "SMALL APRICOTS", hr: "MARELICA SITNA" },
      { en: "PAPAYA CUBES", hr: "PAPAJA KOCKA" },
      { en: "CAROB POWDER", hr: "ROGAČ MLJEVENI" },
      { en: "FIGS", hr: "SMOKVA" },
      { en: "PRUNES", hr: "ŠLJIVA BEZ KOŠTICE" },
      { en: "PLUMS WITH STONE", hr: "ŠLJIVA S KOŠTICOM" },
    ],
  },
  {
    category: {
      en: "Spices",
      hr: "Začini",
    },
    route: {
      en: "spices",
      hr: "zacini",
    },
    image: "spices.jpg",
    imageAlt: "Image by freepik",
    products: [
      { en: "BASIL", hr: "BOSILJAK" },
      { en: "CINNAMON", hr: "CIMET" },
      { en: "CURRY", hr: "CURRY" },
      { en: "CUMIN", hr: "KIM" },
      { en: "BLACK CUMIN/NIGELLA", hr: "CRNI KIM/NIGELLA" },
      { en: "CLOVE", hr: "KLINČIĆ" },
      { en: "TURMERIC", hr: "KURKUMA" },
      { en: "LAUREL", hr: "LOVOR" },
      { en: "OREGANO", hr: "ORIGANO" },
      { en: "BLACK PEPPER WHOLE", hr: "PAPAR CRNI ZRNO" },
      { en: "BLACK PEPPER GROUND", hr: "PAPAR CRNI MLJEVENI" },
      { en: "HOT PAPRIKA", hr: "PAPRIKA LJUTA" },
      { en: "HOT PAPRIKA FLAVORED", hr: "PAPRIKA TUCANA LJUTA" },
      { en: "SWEET PAPRIKA", hr: "PAPRIKA SLATKA" },
      { en: "WHITE GARLIC GRANULES G-2", hr: "LUK BIJELI GRANULE G-2" },
    ],
  },
  {
    category: {
      en: "Sweeteners, Additives, Supplements",
      hr: "Zaslađivači, aditivi, dodaci",
    },
    route: {
      en: "sweeteners-additives-supplements",
      hr: "zasladivaci-aditivi-dodaci",
    },
    image: "sweeteners.jpg",
    imageAlt: "Image by azerbaijan_stockers on Freepik",
    products: [
      { en: "DEXTROSE", hr: "DEXTROZA" },
      { en: "CHOCOLATE POWDER", hr: "ČOKOLADA U PRAHU" },
      {
        en: "ALKALIZED COCOA POWDER 10-12%",
        hr: "KAKAO PRAH ALKALIZIRANI 10-12%",
      },
      { en: "PRESERVATIVES", hr: "KONZERVANS" },
      { en: "CORNSTARCH", hr: "KUKURUZNI ŠKROB" },
      { en: "CITRIC ACID", hr: "LIMUNSKA KISELINA" },
      { en: "BAKING SODA", hr: "SODA BIKARBONA" },
      { en: "CRYSTAL SUGAR", hr: "ŠEĆER KRISTAL" },
      { en: "POWDERED SUGAR", hr: "ŠEĆER MLJEVENI" },
      { en: "WINE BINDER", hr: "VINOBRAN" },
    ],
  },
  {
    category: {
      en: "Dairy Products",
      hr: "Mliječni proizvodi",
    },
    route: {
      en: "dairy-products",
      hr: "mlijecni-proizvodi",
    },
    image: "milk.jpg",
    imageAlt: "Image by freepik",
    products: [
      { en: "FULL-FAT MILK POWDER", hr: "MLIJEKO U PRAHU PUNOMASNO" },
      { en: "SKIM MILK POWDER", hr: "MLIJEKO U PRAHU OBRANO" },
      { en: "MILK POWDER SUBSTITUTE", hr: "ZAMJENSKO MLIJEKO U PRAHU" },
      { en: "WHEY POWDER", hr: "SIRUTKA U PRAHU" },
    ],
  },
];
