// src/pages/NotFoundPage.js
import React from "react";

function NotFoundPage() {
  return (
    <div>
      <h1>404 - Not Found</h1>
      {/* Your NotFoundPage content goes here */}
    </div>
  );
}

export default NotFoundPage;
