import React from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

// Predefined nut-like colors with more options
const nutColors = [
  "#6f4f37", // Brown (Peanut)
  "#8d6e63", // Walnut
  "#7a5c43", // Almond
  "#9e7a44", // Pistachio
  "#d1a15d", // Hazelnut
  "#b17e55", // Pecan
  "#8f7c55", // Macadamia
  "#a87c4e", // Brazil nut
  "#a57c52", // Cashew
  "#c7a45a", // Chestnut
  "#c9b77d", // Pine nut
  "#d0b57d", // Peanut butter tone
  "#bfa64b", // Caramelized walnut
  "#b09a66", // Hazelnut brown
  "#9b7349", // Roasted almond
];

const ProductGrid = ({ products, title, description }) => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const currentLang = i18n.language || "en";

  return (
    <Box
      sx={{
        py: 6,
        px: isMobile ? 6 : 24,
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Carousel Title */}
      {title && (
        <Typography
          variant="h3"
          sx={{
            mt: isMobile ? 2 : 4,
            mb: description ? 1 : 4,
            textAlign: "center", // Centers the title
          }}
        >
          {title}
        </Typography>
      )}
      {/* Carousel Description */}
      {description && (
        <Typography
          variant="body1"
          sx={{ mb: 4, color: "text.secondary", textAlign: "center" }}
        >
          {description}
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 0.5, // Spacing between capsules
          paddingX: isMobile ? 1 : 4,
        }}
      >
        {products.map((product) => (
          <Box
            key={product[currentLang]}
            sx={{
              display: "inline-block",
              backgroundColor: "transparent",
              padding: "10px 20px",
              borderRadius: "50px",
              border: `2px solid ${
                nutColors[Math.floor(Math.random() * nutColors.length)]
              }`,
              transition: "all 0.3s ease",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: "#3e3e3e" }}
            >
              {product[currentLang]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProductGrid;
