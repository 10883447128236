import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import your Header component
import Header from "./components/Header";

// Import your page components
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Category from "./pages/Category";

// Optionally, create a 404 page
import NotFoundPage from "./pages/NotFoundPage";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    // Wrap your entire app in BrowserRouter
    <Router>
      {/* Header is always visible on every page */}
      <Header />

      {/* Define all your routes below */}
      <Routes>
        {/* Home */}
        <Route path="/" element={<HomePage />} />

        {/* About (Croatian path: /o-nama) */}
        <Route path="/o-nama" element={<AboutPage />} />
        <Route path="/about" element={<AboutPage />} />

        {/* Contact (Croatian path: /kontakt) */}
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/contact" element={<ContactPage />} />

        {/* Category (Croatian path: /kategorija/:categoryName) */}
        <Route path="/kategorija/:categoryName" element={<Category />} />
        <Route path="/category/:categoryName" element={<Category />} />

        {/* Optionally, handle 404 or "Not Found" with a fallback route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {/* Footer is always visible on every page */}
      <Footer />

      <ScrollToTop />
    </Router>
  );
}

export default App;
