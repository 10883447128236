import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";

// MUI Icons
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Import your logo (adjust the path as needed)
import logo from "../assets/logo.webp";
import { productCategories } from "../constants/products";

function Header() {
  const { t, i18n } = useTranslation();
  const location = useLocation(); // to detect active route
  const currentLang = i18n.language || "en";
  const otherLang = currentLang === "en" ? "hr" : "en";

  // For desktop, we will hover to open/close the "Products" submenu and the "Language" menu
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);

  // For mobile, we use a Drawer
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  // For mobile "Products" submenu inside Drawer
  const [mobileProductsOpen, setMobileProductsOpen] = useState(false);

  /* --------------------------------------
   *        Desktop Hover Menus
   * -------------------------------------- */
  // Products (hover)
  const handleProductsMouseEnter = (event) => {
    setAnchorElProducts(event.currentTarget);
  };
  const handleProductsMouseLeave = () => {
    setAnchorElProducts(null);
  };

  // Language (hover)
  const handleLangMouseEnter = (event) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleLangMouseLeave = () => {
    setAnchorElLang(null);
  };

  // Switch language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorElLang(null);
  };

  /* --------------------------------------
   *        Mobile Drawer
   * -------------------------------------- */
  const handleOpenMobileDrawer = () => {
    setMobileDrawerOpen(true);
  };

  const handleCloseMobileDrawer = () => {
    setMobileDrawerOpen(false);
  };

  // Toggle mobile products submenu
  const handleMobileProductsToggle = () => {
    setMobileProductsOpen((prev) => !prev);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "rgba(49, 155, 49, 0.6)",
        backdropFilter: "blur(4px)",
      }}
    >
      {/* 
        Use Container with disableGutters to remove extra padding on mobile.
        You can also remove the Container if you want full width.
      */}
      <Container maxWidth="xl" disableGutters>
        <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }}>
          {/* LOGO */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: { xs: 0, sm: 2 },
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={logo}
                alt="HealthFood Wholesale"
                sx={{
                  height: 40,
                  mr: 1,
                }}
              />
            </Link>
          </Box>

          {/* SPACER */}
          <Box sx={{ flexGrow: 1 }} />

          {/* DESKTOP NAV LINKS */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 2,
              mr: 2,
            }}
          >
            {/* HOME */}
            <Button
              component={Link}
              to="/"
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontWeight: location.pathname === "/" ? "bold" : "normal",
              }}
            >
              {t("home")}
            </Button>

            {/* PRODUCTS (hover) */}
            <Box
              onMouseEnter={handleProductsMouseEnter}
              onMouseLeave={handleProductsMouseLeave}
              sx={{ position: "relative" }}
            >
              <Button
                sx={{
                  color: "#fff",
                  textTransform: "uppercase",
                  fontWeight: location.pathname.startsWith(
                    currentLang === "en" ? "/category" : "/kategorija"
                  )
                    ? "bold"
                    : "normal",
                }}
              >
                {t("products")}
                {/* Dropdown icon */}
                <KeyboardArrowDownIcon />
              </Button>

              {/* Submenu on hover: smaller font, enough width */}
              {anchorElProducts && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    backgroundColor: "#fff",
                    boxShadow: 3,
                    zIndex: 9999,
                    minWidth: 220,
                    py: 1,
                  }}
                  onMouseEnter={handleProductsMouseEnter}
                  onMouseLeave={handleProductsMouseLeave}
                >
                  {productCategories.map((cat) => (
                    <Button
                      key={cat.category[currentLang]}
                      component={Link}
                      to={
                        currentLang === "en"
                          ? "category/" + cat.route.en
                          : "kategorija/" + cat.route.hr
                      }
                      sx={{
                        display: "block",
                        color:
                          location.pathname ===
                          (currentLang === "en"
                            ? "/category/" + cat.route.en
                            : "/kategorija/" + cat.route.hr)
                            ? "green"
                            : "black",
                        justifyContent: "flex-start",
                        width: "100%",
                        fontSize: "0.9rem",
                        textAlign: "left",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cat.category[currentLang]}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>

            {/* ABOUT */}
            <Button
              component={Link}
              to="/o-nama"
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontWeight: location.pathname === "/o-nama" ? "bold" : "normal",
              }}
            >
              {t("about")}
            </Button>

            {/* CONTACT */}
            <Button
              component={Link}
              to="/kontakt"
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                fontWeight:
                  location.pathname === "/kontakt" ? "bold" : "normal",
              }}
            >
              {t("contact")}
            </Button>
          </Box>

          {/* DESKTOP LANGUAGE (hover) */}
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              position: "relative",
              mr: { xs: 1, sm: 2 },
            }}
            onMouseEnter={handleLangMouseEnter}
            onMouseLeave={handleLangMouseLeave}
          >
            {/* 
                1) Soft white background 
                2) Dropdown icon 
              */}
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "#fff",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                },
              }}
            >
              <LanguageIcon />
              <Typography variant="body1" sx={{ lineHeight: "1" }}>
                {currentLang.toUpperCase()}
              </Typography>
              <KeyboardArrowDownIcon />
            </Button>

            {/* Language submenu on hover */}
            {anchorElLang && (
              <Box
                sx={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  backgroundColor: "#fff",
                  boxShadow: 3,
                  zIndex: 9999,
                }}
                onMouseEnter={handleLangMouseEnter}
                onMouseLeave={handleLangMouseLeave}
              >
                <Button
                  onClick={() => changeLanguage(otherLang)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    color: "text.primary",
                    width: "100%",
                    ml: 1,
                  }}
                >
                  {otherLang.toUpperCase()}
                </Button>
              </Box>
            )}
          </Box>

          {/* MOBILE HAMBURGER ICON (RIGHT) - hides Language Icon on mobile */}
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <IconButton
              size="large"
              aria-label="open mobile menu"
              onClick={handleOpenMobileDrawer}
              sx={{ color: "#fff" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>

        {/* MOBILE DRAWER */}
        <Drawer
          anchor="right"
          open={mobileDrawerOpen}
          onClose={handleCloseMobileDrawer}
        >
          <Box
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            role="presentation"
          >
            {/* 3) X button at the top */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 1,
              }}
            >
              <IconButton onClick={handleCloseMobileDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider />

            {/* Main menu items */}
            <List sx={{ pt: 0 }}>
              {/* HOME */}
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to="/"
                  onClick={handleCloseMobileDrawer}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: location.pathname === "/" ? "green" : "inherit",
                  }}
                >
                  <Typography variant="subtitle1">{t("home")}</Typography>
                </ListItemButton>
              </ListItem>

              {/* PRODUCTS with Collapse */}
              <ListItemButton
                onClick={handleMobileProductsToggle}
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  color: location.pathname.startsWith(
                    currentLang === "en" ? "/category" : "/kategorija"
                  )
                    ? "green"
                    : "inherit",
                }}
              >
                <Typography variant="subtitle1">{t("products")}</Typography>
                {mobileProductsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              <Collapse in={mobileProductsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {productCategories.map((cat) => (
                    <ListItemButton
                      key={cat.category[currentLang]}
                      component={Link}
                      to={
                        currentLang === "en"
                          ? "category/" + cat.route.en
                          : "kategorija/" + cat.route.hr
                      }
                      onClick={handleCloseMobileDrawer}
                      sx={{
                        pl: 4,
                        color:
                          location.pathname ===
                          (currentLang === "en"
                            ? "/category/" + cat.route.en
                            : "/kategorija/" + cat.route.hr)
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {/* Smaller font, normal text for sub-items */}
                      <Typography
                        variant="body2"
                        sx={{ textTransform: "none" }}
                      >
                        {cat.category[currentLang]}
                      </Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>

              {/* ABOUT */}
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to="/o-nama"
                  onClick={handleCloseMobileDrawer}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color:
                      location.pathname === "/o-nama" ? "green" : "inherit",
                  }}
                >
                  <Typography variant="subtitle1">{t("about")}</Typography>
                </ListItemButton>
              </ListItem>

              {/* CONTACT */}
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to="/kontakt"
                  onClick={handleCloseMobileDrawer}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color:
                      location.pathname === "/kontakt" ? "green" : "inherit",
                  }}
                >
                  <Typography variant="subtitle1">{t("contact")}</Typography>
                </ListItemButton>
              </ListItem>

              <Divider sx={{ my: 1 }} />

              {/* 5) & 6) Language button (same style as desktop; removing "Language:" text) */}
              <ListItemButton
                onClick={() => changeLanguage(otherLang)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                  },
                  textTransform: "none",
                  justifyContent: "flex-start",
                }}
              >
                <LanguageIcon />
                <Typography variant="body1" sx={{ lineHeight: "1" }}>
                  {otherLang.toUpperCase()}
                </Typography>
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
      </Container>
    </AppBar>
  );
}

export default Header;
