import { Box } from "@mui/material";
import React from "react";

const Map = ({
  location, // Object with latitude and longitude
  zoom = 15, // Default zoom level
  mapWidth = "100%", // Default map width
  mapHeight = "50vh", // Default map height
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: mapWidth,
        height: mapHeight,
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      {/* Google Maps Embed */}
      <iframe
        title="google-map"
        src={`https://maps.google.com/maps?q=${location.lat},${location.lng}&z=${zoom}&output=embed`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        allowFullScreen
      />
    </Box>
  );
};

export default Map;
