// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import en from "./translations/en.json";
import hr from "./translations/hr.json";

i18n.use(LanguageDetector); // Detects language from browser settings, localStorage, etc. .
i18n.use(initReactI18next); // Passes i18n down to react-i18next .
i18n.init({
  resources: { en: { translation: en }, hr: { translation: hr } },
  fallbackLng: "en", // If no language detected, fall back to English
  debug: false, // Set true only for debugging
  interpolation: {
    escapeValue: false, // React already does XSS protection
  },
});

if (
  i18n.language !== "hr" &&
  i18n.language !== "hr-HR" &&
  i18n.language !== "hr-BA" &&
  i18n.language !== "hr-RS" &&
  i18n.language !== "hr-ME" &&
  i18n.language !== "hr-SI" &&
  i18n.language !== "hr-XK" &&
  i18n.language !== "hr-IT" &&
  i18n.language !== "hr-HU"
) {
  i18n.changeLanguage("en");
} else {
  i18n.changeLanguage("hr");
}

export default i18n;
